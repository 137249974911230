<template>
  <div class="presence-row">
    <div v-if="isLoading">
      <a-spin size="small" />
    </div>
    <div v-if="!isLoading && presence">
      <div class="flex-wrapper">
        <div class="left">
          <presence-thumbnail
            :owner-id="member.groupId"
            :presence-id="presence.id"
          ></presence-thumbnail>
        </div>
        <div class="right">
          {{ presence.displayName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import presences from "../../../../api/presences";
import PresenceThumbnail from "../../../../components/Presences/PresenceThumbnail.vue";
export default {
  components: { PresenceThumbnail },
  props: ["member", "tenantId"],
  data() {
    return {
      isLoading: false,
      presence: null,
    };
  },
  created() {
    this.loadPresence();
  },
  methods: {
    loadPresence() {
      let vm = this;
      vm.isLoading = true;
      presences
        .getPresence(this.tenantId, this.member.presenceId)
        .then((r) => {
          vm.isLoading = false;
          vm.presence = r.data;
        })
        .catch((e) => {
          console.log(e);
          // vm.$message.error("Error loading presence");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.flex-wrapper {
  display: flex;
  .left {
    flex-shrink: 1;
    padding-right: 15px;
  }
  .right {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
}
</style>