<template>
  <div class="announcement-response-card"
    :class="{'is-inline': isInline}"
   @click.prevent="toggleExpanded">
    <!-- Flex wrapper -->
    <div class="flex-wrapper">
      <div class="left">
        <a-avatar size="large" :style="avatarStyle">
          {{ responseGroup.members.length }}
        </a-avatar>
      </div>
      <div class="center">
        <div class="name">{{ responseGroup.name }}</div>
        <div
          class="members-summary"
          v-if="responseGroup.members.length && !expanded && !isInline"
        >
          <member-badge
            v-for="member in responseMembersToShow"
            :key="getMemberKey(member.presenceId)"
            :tenant-id="tenantId"
            :announcement-id="announcementId"
            :member="member"
            :is-inline="isInline"
          ></member-badge>
          <span style="margin-left: 3px" v-if="numberOfMembersNotShown > 0"
            >+ {{ numberOfMembersNotShown }} more</span
          >
        </div>
      </div>
      <div v-if="responseGroup.members.length" class="right">
        <a-icon v-if="expanded" type="up" />
        <a-icon v-else type="down" />
      </div>
    </div>
    <!-- / Flex wrapper -->

    <!-- Presences (Expanded) -->
    <div class="presences-expanded" v-if="expanded">
      <presence-row
        v-for="(member, i) in responseGroup.members"
        :tenant-id="tenantId"
        :member="member"
        :key="getPresenceRowKey(i)"
      ></presence-row>
    </div>
    <!-- / Presences (Expanded) -->
  </div>
</template>

<script>
import MemberBadge from "./AnnouncementResponseCard/MemberBadge.vue";
import PresenceRow from "./AnnouncementResponseCard/PresenceRow.vue";
const _ = require("lodash");

export default {
  components: { MemberBadge, PresenceRow },
  data() {
    return {
      expanded: false,
      baseKey: Math.random()
    };
  },
  props: ["responseGroup", "tenantId", "announcementId", "isInline"],
  computed: {
    responseGroupMembersOrderedByLastResponse() {
      let withResponse = _.filter(this.responseGroup.members, (member) => {
        return (
          member["content"] &&
          member["content"]["$v"] &&
          member["content"]["$v"]["responseTime"]
        );
      });
      let withResponseSorted = _.orderBy(withResponse, "content.$v.responseTime", "desc");

      let withoutResponse = _.filter(this.responseGroup.members, (member) => {
        return !(
          member["content"] &&
          member["content"]["$v"] &&
          member["content"]["$v"]["responseTime"]
        );
      });

      return _.flattenDeep([withResponseSorted, withoutResponse]);
    },
    responseMembersToShow() {
      if (this.responseGroup.members.length > 4) {
        return this.responseGroupMembersOrderedByLastResponse.slice(0, 4);
      }
      return this.responseGroupMembersOrderedByLastResponse;
    },
    numberOfMembersNotShown() {
      let no = this.responseGroup.members.length - 4;
      return no > 0 ? no : 0;
    },
    avatarStyle() {
      let styles = {};

      if (this.responseGroup.backgroundColour) {
        styles["background"] = this.responseGroup["backgroundColour"];
      }

      if (this.responseGroup.foregroundColour) {
        styles["color"] = this.responseGroup["foregroundColour"];
      }

      return styles;
    },
  },
  methods: {
    toggleExpanded() {
      let vm = this;
      vm.expanded = !vm.expanded;
    },

    getPresenceRowKey(i) {
      return this.baseKey + "-presence-" + i;
      // return "presence-row-" + i;
    },

    getMemberKey(i) {
      return this.baseKey + "-member-" + i;
    },
  },
};
</script>

<style scoped lang="scss">
.announcement-response-card {

  background: #fff;

  padding: 20px;
  border-radius: 6px;
  border: 1px solid #fff;
  cursor: pointer;

  &.is-inline {
    // padding-top: 15px;
    // padding-bottom: 15px;
    padding: 11px;
  }

  .flex-wrapper {
    display: flex;
  }

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    min-width: 45px;
    text-align: center;
  }

  .center {
    padding-left: 15px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
  }

  .right {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }

  .name {
    font-weight: 500;
  }

  &:hover:not(.is-inline) {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  .members-summary {
    margin-top: 10px;

    .member-badge {
      margin-right: 5px;
    }
  }

  .presences-expanded {
    padding-top: 20px;

    .presence-row {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>